body {
  margin: 0 !important;
  padding: 0;
}

* {
  -webkit-touch-callout: none !important;
  -webkit-text-size-adjust: none !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  -webkit-tap-highlight-color: transparent !important;
}

.generalpage{
  height: 100vh;
  display: flex;
  flex-direction: column;

  //-webkit-flex: 1 64px;
  //-ms-flex: 1 64px;
}

.main {
  flex-shrink: 0;
  min-height: calc(100% - 314px);
}

