.fields {
  display: flex;
  h2 {
    font-family: "Open Sans";
    font-size: 16px;
    margin: 0px;
    line-height: 0;
    margin-top: 12px;
    margin-bottom: 60px;
  }

  margin-bottom: 112px;
}
.col1 , .col2 {
  display: flex;
  flex-direction: column;

}
.Input {
  margin-bottom: 41px !important;

}

.col1 {
  margin-right: 110px;

}




