

.customselect {


  .MuiInputBase-root.Mui-focused fieldset {
    border: 2px solid #b7b7b7 !important;
  }
  .MuiSelect-select:focus {
    background-color: white !important;
  }
  .MuiSelect-select:focus .MuiInputBase-root fieldset{
    border: 2px solid #b7b7b7 !important;
  }

  .MuiInputBase-root .MuiSelect-icon{
    background-color: white;
    position: absolute;
    transform: translateY(8px) translateX(2px);
    background: url("../../assets/img/caret-down.svg") no-repeat;

  }
  .MuiInputBase-root .MuiSelect-icon path {
    display: none !important;
  }
}

.MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: 100% !important;
  font-family: "Source Sans Pro" !important;

}
.MuiPaper-root {
  box-shadow: 0 3px 8px rgba(1, 1, 1, 0.24) !important;
  transform: translateY(-0.5px) !important;
}

.MuiList-root.Mui-focused {
  background-color: white;
}
.MuiListItem-root {
  padding: 16px  !important;
  color: #393939;
  font-family: "Source Sans Pro" !important;
  font-size: 16px;
}

.MuiListItem-root:hover {
  background-color: rgba(239, 108, 0, 0.2) !important;
}

.MuiListItem-root.Mui-selected {
  background-color: white !important;
  color: #ED8A3C !important;
  cursor: default;

}

.MuiMenuItem-gutters {

}

* {

}


