.emailLink{
  p{
    transition: .2s;
  }
  &:hover{
    p{
      color: #ef6c00 !important;
      transition: .2s;
    }
    .svgi{
      fill: #ef6c00 ;
    }
  }
}
.mailIconHover{
  transform: translateY(10px);
}
