
@import url('https://fonts.googleapis.com/css?family=Overpass:600&display=swap');

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

@media only screen and (min-width: 1024px) {
  h1 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 60px;
    font-size: 50px;
  }
  h2 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 51px;
    font-size: 42px;
  }
  h3 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 31px;
    font-size: 26px;
  }
  h4 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 26px;
    font-size: 22px;
  }
  h5 {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    line-height: 21px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  h1 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 51px;
    font-size: 42px;
  }
  h2 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 51px;
    font-size: 42px;
  }
  h3 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 31px;
    font-size: 26px;
  }
  h4 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 26px;
    font-size: 22px;
  }
  h5 {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    line-height: 21px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px){
  h1 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 36px;
    font-size: 30px;
  }
  h2 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 31px;
    font-size: 26px;
  }
  h3 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 26px;
    font-size: 22px;
  }
  h4 {
    font-family: 'Overpass';
    font-weight: 600;
    line-height: 26px;
    font-size: 22px;
  }
  h5 {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
  }
}

.prg1 {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  line-height: 26px;
  font-size: 18px;
  margin: 0;

}

.prg2 {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  line-height: 22px;
  font-size: 16px;
  margin: 0;
}

.prg3 {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  margin: 0;
}



